/* eslint-disable class-methods-use-this */
import React, { useEffect, useMemo, useState } from 'react';

import './App.scss';
import {
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import { observer } from 'mobx-react';
// import { AnimatePresence } from 'framer-motion';
import Mousetrap from 'mousetrap';
import NavBar from './components/NavBar/NavBar';
import HomePage from './components/HomePage/HomePage';
import Pictures from './components/Pictures/Pictures';
import Contact from './components/Contact/Contact';
import About from './components/About/About';
import GlobalStore from './Stores/GlobalStore';
import LoveJacqueline from './components/LoveJacqueline/LoveJacqueline';
// import Login from './components/Login/Login';
// import Register from './components/Register/Register';

const SwitchWrapper = observer(({ location }) => {
  const globalStore = useMemo(() => new GlobalStore(), []);

  return (
    <>

      <NavBar />
      {/* <AnimatePresence> */}
      <Switch location={location}>
        {/* <Route path="/books/unread" render={() => <Books />} />
    <Route path="/books/favourite" render={() => <Books />} />
    <Route path="/books/finished" render={() => <Books />} />
    <Route path="/books/:bookId" render={({ match }) => <Books bookId={match.params.bookId} />}
    /> */}
        <Route path="/contact" render={() => <Contact globalStore={globalStore} />} />
        <Route path="/about" render={() => <About />} />
        <Route path="/landscapes/:pictureId" render={({ match }) => <Pictures pictureId={match.params.pictureId} globalStore={globalStore} params={match} />} />
        <Route path="/flowers/:pictureId" render={({ match }) => <Pictures pictureId={match.params.pictureId} globalStore={globalStore} params={match} />} />
        <Route path="/mix-media-works/:pictureId" render={({ match }) => <Pictures pictureId={match.params.pictureId} globalStore={globalStore} params={match} />} />
        <Route path="/others/:pictureId" render={({ match }) => <Pictures pictureId={match.params.pictureId} globalStore={globalStore} params={match} />} />
        <Route path="/flowers" render={({ match }) => <Pictures globalStore={globalStore} params={match.path} />} />
        <Route path="/landscapes" render={({ match }) => <Pictures globalStore={globalStore} params={match.path} />} />
        <Route path="/mix-media-works" render={({ match }) => <Pictures globalStore={globalStore} params={match.path} />} />
        <Route path="/others" render={({ match }) => <Pictures globalStore={globalStore} params={match.path} />} />
        <Route path="/" render={() => <HomePage />} />
      </Switch>
      {/* </AnimatePresence> */}

    </>
  );
});

function App() {
  const location = useLocation();
  const [loveVisible, setLoveVisible] = useState(false);

  useEffect(() => {
    Mousetrap.bind('j a c q u e l i n e', () => { setLoveVisible(true); });
  }, []);

  return (
    <div className="App">
      <LoveJacqueline loveVisible={loveVisible} setLoveVisible={setLoveVisible} />
      <SwitchWrapper location={location} />
    </div>
  );
}

export default App;
