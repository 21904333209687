/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import { Helmet } from 'react-helmet';
import Image from '../../img/about1.jpg';
import Image2 from '../../img/about_missher.jpeg';
import { ReactComponent as Facebook } from '../../img/facebook.svg';
import { ReactComponent as Instagram } from '../../img/instagram.svg';

const About = () => (
  <div className="About">
    <Helmet>
      <meta charSet="utf-8" />
      <meta property="og:image" content="https://ora-attachments.s3.amazonaws.com/12661954fd98519fb37705d8e2f2682d52568276f7261.jpeg" />
      <meta property="og:title" content="Missher Art | About" />
      <title>Missher Art | About</title>
      {/* <link rel="canonical" href="http://mysite.com/example" /> */}
    </Helmet>

    <img src={Image2} alt="author" className="responsive-image" />
    <div style={{
      padding: 16, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', letterSpacing: 1.5,
    }}
    >
      <div style={{
        fontSize: 24, fontWeight: 700, paddingBottom: 8, marginLeft: '5%', marginRight: '5%', marginTop: 16, marginBottom: 16,
      }}
      >
        My name is Mihaela but everyone calls me Missher.
      </div>
      <div
        className="responsive-text"
        style={{
          fontSize: 20, fontWeight: 400, lineHeight: 1.66, letterSpacing: 1.5, marginBottom: 16,
        }}
      >
        I grow up in a small city in Bulgaria - Gorna Oriahovitsa, but moved to Sofia in 2009.
      </div>
      <div
        className="responsive-text"
        style={{
          fontSize: 20, fontWeight: 400, lineHeight: 1.66, letterSpacing: 1.5, marginBottom: 16,
        }}
      >
        From the beginning, my whole life has been orientated in the artistic direction. I was a dancer for 10 years, now a musician in the alternative rock band ONE DAY LESS, and an abstract painter. Yes, I always find time to follow my dreams and I just love to keep my mind and creativity in a dynamic shape, otherwise I feel lost.
        I can define myself as a self-taught artist in all the fields in which I have performed.

      </div>
      <div
        className="responsive-text"
        style={{
          fontSize: 20, fontWeight: 400, lineHeight: 1.66, letterSpacing: 1.5, marginBottom: 16,
        }}
      >
        I describe the watercolor technique as my favourite, because it is spontaneous, as I am in life. It suits my temperament perfectly. Working with water balances me, it acts as a kind of meditation for me. It brings me joy! In the moments when I take a rest from painting with watercolour, I love to create huge mix-media abstract canvases, improvising and mixing textures with different materials. I mostly work with acrylic, gouache, texture paste and pastels.
      </div>
      <div
        className="responsive-text"
        style={{
          fontSize: 20, fontWeight: 400, lineHeight: 1.66, letterSpacing: 1.5, marginBottom: 16,
        }}
      >
        The best thing in the abstract magic is that even the viewers' moods are reflected in the picture, so everyone can find in my paintings what they were looking for in themselves for long time. Abstract can develop your imagination and throw your senses into another much more beautiful dimension.
      </div>
    </div>

    <img src={Image} alt="author" className="responsive-image" />
    {/* <Image2 /> */}
    <div style={{
      fontSize: 24, fontWeight: 700, marginTop: 24, letterSpacing: 1.5, marginBottom: 16,
    }}
    >
      Exhibitions:
    </div>
    <ul style={{ marginTop: 0, marginBottom: 8 }}>
      <li style={{ letterSpacing: 1.5, padding: 8 }}>
        8 Oct. 2020 - "Dance with water" /Palitra Gallery, Sofia
      </li>
      <li style={{ letterSpacing: 1.5, padding: 8 }}>
        3 June 2021 - City Art Gallery Asen and Iliya Peikovi, Sevlievo
      </li>
      <li style={{ letterSpacing: 1.5, padding: 8, paddingBottom: 24 }}>
        17 Dec 2021 - "Flowers for Margarita" / Palitra Gallery, Sofia
      </li>
    </ul>
    <div>
      <Facebook style={{ margin: 4, marginBottom: 24 }} onClick={() => window.open('https://www.facebook.com/MisshersCreativity')} className="hand logo" width="40" height="40" />
      <Instagram style={{ margin: 4, marginBottom: 24 }} onClick={() => window.open('https://www.instagram.com/missher_art/')} className="hand logo" width="40" height="40" />
    </div>
  </div>
);

export default About;
