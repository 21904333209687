/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Lightbox from 'react-spring-lightbox';
import { FacebookIcon, FacebookShareButton } from 'react-share';
import { Snackbar } from '@mui/material';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';
import Image, { copyToClipboard } from './Image';
import { ReactComponent as Share } from '../../img/share.svg';
import { ReactComponent as CopyImage } from '../../img/link.svg';
import { ReactComponent as Close } from '../../img/close.svg';

// const ImageOverlay = observer(() => {
//   const bo = 'cool';

//   return (
//     <div className="lightbox-image-overlay">
//       {bo}
//     </div>
//   );
// });

const CustomHeader = ({ onClose }: {onClose(): void}) => (
  <motion.div
    className="center-flex hand"
    onMouseUp={() => onClose()}
    whileHover={{ backgroundColor: 'rgba(0, 0, 0, 0.652)', scale: 1.1 }}
    whileTap={{ backgroundColor: 'rgba(0, 0, 0, 0.652)', scale: 1.2 }}
    style={{
      position: 'absolute', top: 0, right: 0, padding: 8, margin: 8, borderRadius: '20px', backgroundColor: 'rgba(0, 0, 0, 0.522)', zIndex: 3000,
    }}
  >
    <Close fill="white" />
  </motion.div>
);

const CustomFooter = observer(({
  currentImageIndex, params, globalStore, setImageOpen, url,
}) => {
  // if (!data) return null;

  const id = 12;
  const history = useHistory();
  const [menuVisible, setMenuVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const data = useMemo(() => {
    if (params.path === '/landscapes/:pictureId') return globalStore.landscapes[currentImageIndex];
    if (params.path === '/flowers/:pictureId') return globalStore.flowers[currentImageIndex];
    if (params.path === '/mix-media-works/:pictureId') return globalStore.mixMedia[currentImageIndex];
    if (params.path === '/others/:pictureId') return globalStore.others[currentImageIndex];
    return undefined;
  }, [params, currentImageIndex]);
  if (!data) return null;
  const { attributes } = data || {};

  return (
    <div className="custom-footer" style={{ paddingBottom: 16 }}>

      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        message="Link copied!"
      />
      <div className="title">
        {attributes?.Title}
      </div>
      <div className="price">
        {attributes.Price}
        {' '}
        BGN
      </div>
      <div className="size">
        {attributes.Size}
        {' '}
        cm
      </div>
      {attributes.creationDate && (
      <div className="size">
        {dayjs(attributes.creationDate).format('YYYY') }
      </div>
      )}
      {attributes.Technique && (
      <div className="price">
        {attributes.Technique}
      </div>
      )}
      <div
        onClick={() => {
          setImageOpen(false);
          history.push('/contact');
          globalStore.setSelectedImage(attributes);
        }}
        className={attributes.Availability ? 'available hand' : 'sold hand'}
      >
        {attributes.Availability ? 'Purchase' : 'sold'}
      </div>
      <div className="grow" />
      <AnimatePresence>
        {menuVisible && (
          <>
            <FacebookShareButton url={url ? `https://missher.art${url}` : `https://missher.art${params}/${id}`}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  right: 9,
                  bottom: 140,
                  zIndex: 1000,
                  // marginRight: '5%',
                  marginLeft: '5%',
                }}
              >
                <FacebookIcon round size={55} style={{ marginRight: 4 }} />
              </motion.div>
            </FacebookShareButton>

            <motion.div
              className="hand"
              onClick={() => {
                copyToClipboard(url ? `https://missher.art${url}` : `https://missher.art${params}/${id}`);
                setOpen(true);
                setMenuVisible(false);
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                right: 9,
                bottom: 70,
                zIndex: 1000,
                // marginLeft: '5%',
                margin: 6,
                borderRadius: '40px',
                background: 'rgb(0, 0, 0, 63%)',
              }}
            >

              <CopyImage
                color="white"
                style={{ margin: 16 }}
                className="hover-menu hand"
                onMouseEnter={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
                width="20"
                height="20"
              />
            </motion.div>

          </>
        )}
      </AnimatePresence>
      <motion.div
        className="shareButton"
        onClick={() => setMenuVisible(!menuVisible)}
        // className="hover-menu"
        initial={{ position: 'absolute', opacity: 0 }}
        animate={{ position: 'absolute', opacity: 1 }}
        exit={{ position: 'absolute', opacity: 0 }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          right: 0,
          bottom: 0,
          zIndex: 1000,

        }}
      >
        <motion.div
          className="hand"
          style={{
            margin: 16,
            borderRadius: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgb(0, 0, 0, 63%)',
            // width: 50,
            // height: 50,
          }}
        >
          <Share
            color="white"
            style={{ margin: 16 }}
            className="hover-menu hand"
            onMouseEnter={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
            width="20"
            height="20"
          />
        </motion.div>

      </motion.div>
    </div>
  );
});

const Pictures = observer(({ params, globalStore, pictureId }: any) => {
  const [imageOpen, setImageOpen] = useState(false);
  const [currentImageIndex, setCurrentIndex] = useState(0);
  const history = useHistory();
  const [fuck, setFuck] = useState(false);

  const category = useMemo(() => {
    if (params.path?.includes?.('pictureId')) {
      return params.path.split('/:pictureId')[0];
    }
    return params;
  }, [params, globalStore.data]);

  useEffect(() => {
    // if (!globalStore.data) return;
    if (globalStore.data) {
      if (params.path?.includes?.('pictureId')) {
        if (category === '/landscapes') {
          globalStore.landscapes.forEach((image, i) => {
            if (String(image?.id) === String(pictureId)) {
              setCurrentIndex(i);
              setImageOpen(true);
            }
          });
        }
        if (category === '/flowers') {
          globalStore.flowers.forEach((image, i) => {
            if (String(image?.id) === String(pictureId)) {
              setCurrentIndex(i);
              setImageOpen(true);
            }
          });
        }
        if (category === '/mix-media-works') {
          globalStore.mixMedia.forEach((image, i) => {
            if (String(image?.id) === String(pictureId)) {
              setCurrentIndex(i);
              setImageOpen(true);
            }
          });
        }
      }
      globalStore.setImagePage(category);
    }
    return () => globalStore.setImagePage('');
  }, [category, globalStore.data, params, pictureId, fuck]);

  const gotoPrevious = () => {
    if (currentImageIndex > 0) {
      setFuck(true);
      setCurrentIndex(currentImageIndex - 1);
      history.push(`${category}/${globalStore.imagesLightboxData[currentImageIndex - 1]?.id}`);
    }
  };

  const gotoNext = () => {
    if (currentImageIndex + 1 < globalStore.imagesLightboxData.length) {
      setFuck(true);
      setCurrentIndex(currentImageIndex + 1);
      history.push(`${category}/${globalStore.imagesLightboxData[currentImageIndex + 1]?.id}`);
    }
  };

  // if (!currentImageIndex) return null;

  return (
    <div
      className="Pictures"
    >
      <>
        {/* {params.path?.includes?.('pictureId') && globalStore.imagesLightboxData[currentImageIndex] && ( */}
        <Helmet>
          <meta charSet="utf-8" />
          {/* <meta property="og:image" content={globalStore.imagesLightboxData[currentImageIndex]?.url} /> */}

          <meta property="og:title" content="Missher Art | Gallery" />
          <title>Missher Art | Gallery</title>
        </Helmet>
        {/* )} */}
        {category === '/landscapes' && globalStore.landscapes.map((image, index) => (
          <Image
            params={params}
            url={params.url}
            id={image?.id}
            category={category}
            setIndex={setCurrentIndex}
            index={index}
            openImage={setImageOpen}
            data={image.attributes}
          />
        ))}
        {category === '/flowers' && globalStore.flowers.map((image, index) => (
          <Image
            params={params}
            url={params.url}
            id={image?.id}
            category={category}
            setIndex={setCurrentIndex}
            index={index}
            openImage={setImageOpen}
            data={image.attributes}
          />
        ))}
        {category === '/mix-media-works' && globalStore.mixMedia.map((image, index) => (
          <Image
            params={params}
            url={params.url}
            id={image?.id}
            category={category}
            setIndex={setCurrentIndex}
            index={index}
            openImage={setImageOpen}
            data={image.attributes}
          />
        ))}

        {category === '/others' && globalStore.others.map((image, index) => (
          <Image
            params={params}
            url={params.url}
            id={image?.id}
            category={category}
            setIndex={setCurrentIndex}
            index={index}
            openImage={setImageOpen}
            data={image.attributes}
          />
        ))}

      </>
      <Lightbox
        // renderImageOverlay={() => <ImageOverlay />}
        renderHeader={() => (
          <CustomHeader onClose={() => {
            setImageOpen(false);
            history.push(category);
          }}
          />
        )}
        renderFooter={() => (
          <CustomFooter
            url={params.url}
            globalStore={globalStore}
            currentImageIndex={currentImageIndex}
            params={params}
            setImageOpen={setImageOpen}
          />
        )}
        style={{ background: 'rgba(0, 0, 0, 0.822)' }}
        isOpen={imageOpen}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        images={globalStore.imagesLightboxData}
        onClose={() => {
          setImageOpen(false);
          history.push(category);
          // setCurrentIndex(0);
        }}
        currentIndex={currentImageIndex}
      />
    </div>
  );
});

export default Pictures;
