/* eslint-disable no-param-reassign */
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  FacebookIcon, FacebookShareButton,
} from 'react-share';
import { Snackbar } from '@mui/material';
import { observer } from 'mobx-react';
import { ReactComponent as Share } from '../../img/share.svg';
import { ReactComponent as CopyImage } from '../../img/link.svg';
// import { ReactComponent as Facebook } from '../../img/facebook.svg';
// import { API_URL } from '../../api/api';

export function checkIfElementHasIdentifier(elementPath, elementIdentifier, nestDepth) {
  if (!elementIdentifier || !elementPath || !nestDepth) return;
  // eslint-disable-next-line no-plusplus
  // eslint-disable-next-line no-param-reassign
  // eslint-disable-next-line no-plusplus
  nestDepth++;
  let containsElement = false;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < nestDepth; i++) {
    // eslint-disable-next-line no-continue
    if (!elementPath[i]) continue;
    if (elementPath[i]?.classList?.contains(elementIdentifier)) {
      containsElement = true;
      break;
    }
  }

  // eslint-disable-next-line consistent-return
  if (containsElement) return true;
  // eslint-disable-next-line consistent-return
  return false;
}

export function eventPath(event) {
  let path = event.path || event.composedPath?.();
  if (!path) {
    path = [];
    let node = event.target;
    while (node !== document.body) {
      path.push(node);
      node = node.parentNode;
    }
  }
  return path || [];
}

export function copyToClipboard(string) {
  let textarea;
  let result;

  try {
    textarea = document.createElement('textarea');
    textarea.setAttribute('readonly', true);
    textarea.setAttribute('contenteditable', true);
    textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
    textarea.value = string;

    document.body.appendChild(textarea);

    textarea.focus();
    textarea.select();

    const range = document.createRange();
    range.selectNodeContents(textarea);

    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);

    textarea.setSelectionRange(0, textarea.value.length);
    result = document.execCommand('copy');
  } catch (err) {
    console.error(err);
    result = null;
  } finally {
    document.body.removeChild(textarea);
  }

  // manual copy fallback using prompt
  if (!result) {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
    result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
    if (!result) {
      return false;
    }
  }
  return true;
}

interface HoverMenuInterface{
  // eslint-disable-next-line no-unused-vars
  setOpen(boolean): void
  url: string
  id: number
  // eslint-disable-next-line no-unused-vars
  setIsHovering(boolean): void
  data: any
  isHovering: boolean
  params: any
}

const HoverMenu = observer(({
  setIsHovering, isHovering, setOpen, url, id, params,
}: HoverMenuInterface) => {
  const [menuVisible, setMenuVisible] = useState(false);

  return (
    <>
      <AnimatePresence>
        {menuVisible && isHovering && (
          <>
            <FacebookShareButton url={url ? `https://missher.art${url}` : `https://missher.art${params}/${id}`}>
              <motion.div
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  right: 34,
                  bottom: 140,
                  zIndex: 1000,
                  marginLeft: '5%',
                }}
              >
                <FacebookIcon round size={55} style={{ marginRight: 4 }} />
              </motion.div>
            </FacebookShareButton>

            <motion.div
              className="hand"
              onClick={() => {
                copyToClipboard(url ? `https://missher.art${url}` : `https://missher.art${params}/${id}`);
                // navigator.clipboard.writeText(url ? `https://missher.art${url}` : `https://missher.art${params}/${id}`);
                setOpen(true);
                setMenuVisible(false);
              }}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                right: 34,
                bottom: 70,
                zIndex: 1000,
                // marginLeft: '5%',
                margin: 6,
                borderRadius: '40px',
                background: 'rgb(0, 0, 0, 63%)',
              }}
            >

              <CopyImage
                color="white"
                style={{ margin: 16 }}
                className="hover-menu hand"
                onMouseEnter={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
                width="20"
                height="20"
              />
            </motion.div>

          </>
        )}
      </AnimatePresence>
      <motion.div
        onClick={() => setMenuVisible(!menuVisible)}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        // className="hover-menu"
        initial={{ position: 'absolute', opacity: 0 }}
        animate={{ position: 'absolute', opacity: 1 }}
        exit={{ position: 'absolute', opacity: 0 }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          right: 0,
          bottom: 0,
          zIndex: 1000,
          marginRight: '5%',
          marginLeft: '5%',

        }}
      >
        <motion.div
          className="hand"
          style={{
            margin: 16,
            borderRadius: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgb(0, 0, 0, 63%)',
            // width: 50,
            // height: 50,
          }}
        >
          <Share
            color="white"
            style={{ margin: 16 }}
            className="hover-menu hand"
            onMouseEnter={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
            width="20"
            height="20"
          />
        </motion.div>

      </motion.div>
    </>
  );
});

const Image = ({
  data, openImage, index, setIndex, category, id, url, params,
}: any) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const image = data?.cover.data?.attributes;
  const imageUrl = image?.url;
  const [isHovering, setIsHovering] = useState(false);
  const interiorUrl = data?.interior?.data?.attributes.url;

  return (
    <div className="image-container">
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        message="Link copied!"
      />
      <div
        style={{ maxHeight: '100%', width: '100%', position: 'relative' }}
      >
        <motion.img
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onClick={() => {
            history.push(`${category}/${id}`);
            setIndex(index);
            openImage(true);
          }}
          transition={{ duration: 1 }}
          whileHover={interiorUrl && { opacity: 0 }}
          className="image hand"
          src={`${imageUrl}`}
          alt="asd"
        />
        <AnimatePresence>

          {isHovering && (
            <>
              {interiorUrl && (
              <motion.img
                transition={{ duration: 1.5 }}
                style={{ top: 0, left: 0, position: 'absolute' }}
                initial={{ position: 'absolute', opacity: 0 }}
                animate={{ position: 'absolute', opacity: 1 }}
                exit={{ position: 'absolute', opacity: 0 }}
                className="interior-image hand"
                src={`${interiorUrl}`}
                alt="asd"
              />
              )}
              <HoverMenu
                params={params}
                id={id}
                url={url}
                setIsHovering={setIsHovering}
                data={data}
                isHovering={isHovering}
                setOpen={setOpen}
              />
            </>
          )}
        </AnimatePresence>
      </div>

      <div className="title p">
        {data.Title}
      </div>
      <div className={data.Availability ? 'p available' : 'p sold'}>
        {data.Availability ? 'available' : 'sold'}
      </div>
    </div>
  );
};

export default Image;
