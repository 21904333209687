/* eslint-disable max-len */
import { action, computed, observable } from 'mobx';
import api from '../api/api';

// interface UserStoreInterface{
//     loggedIn: boolean;
// }

class GlobalStore {
  // loggedIn: boolean

  @observable data

  @observable imagePage = ''

  @observable selectedImage = {}

  constructor() {
    api('api/pictures').then(({ data: serverData }: any) => {
      this.data = serverData.data;
    });
  }

  @action.bound setImagePage(arg: string) {
    this.imagePage = arg;
  }

  @action.bound setSelectedImage(data) {
    this.selectedImage = data;
  }

  @computed get imagesLightboxData() {
    if (!this.data) return [];
    let images;
    if (this.imagePage === '/flowers') images = this.flowers;
    if (this.imagePage === '/landscapes') images = this.landscapes;
    if (this.imagePage === '/mix-media-works') images = this.mixMedia;
    if (this.imagePage === '/others') images = this.others;
    if (!images?.length) return [];
    return images.map((image) => {
      const { attributes } = image;
      return {
        src: attributes.cover.data.attributes.url, alt: `${attributes.Title} cover`, loading: 'auto', id: image.id, title: attributes.Title,
      };
    });
  }

  @computed get landscapes() {
    if (!this.data) return [];
    // eslint-disable-next-line max-len
    return this.data.filter((image) => image.attributes.landscapes).sort((a, b) => a.id - b.id).reverse();
  }

  @computed get flowers() {
    if (!this.data) return [];
    // eslint-disable-next-line max-len
    return this.data.filter((image) => image.attributes.flowers).sort((a, b) => a.id - b.id).reverse();
  }

  @computed get mixMedia() {
    if (!this.data) return [];
    // eslint-disable-next-line max-len
    return this.data.filter((image) => image.attributes.Mixmedia).sort((a, b) => a.id - b.id).reverse();
  }

  @computed get others() {
    if (!this.data) return [];
    // eslint-disable-next-line max-len
    return this.data.filter((image) => image.attributes.Others).sort((a, b) => a.id - b.id).reverse();
  }
}

export default GlobalStore;
