/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import Missher from '../../img/about1.jpeg';
import { ReactComponent as Facebook } from '../../img/facebook.svg';
import { ReactComponent as Instagram } from '../../img/instagram.svg';

const HomePage = () => {
  const [shown, setShown] = useState(false);
  const history = useHistory();

  return (
    <div className="HomePage">
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:image" content="https://ora-attachments.s3.amazonaws.com/12661954fd98519fb37705d8e2f2682d52568276f7261.jpeg" />

        <meta property="og:title" content="Missher Art" />
        <title>Missher art</title>
      </Helmet>
      <div className="header-container">
        <div className="header">
          Missher
        </div>
        <div className="sub-header">
          Abstract painter
        </div>
      </div>
      <img className="responsive-image image" src={Missher} alt="author" />
      <div className="links">
        <div
                // style={{ position: 'relative' }}
          className="link"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setShown(!shown);
          }}
        >
          Watercolours
          <AnimatePresence>
            {shown && (
            <motion.div
              transition={{ duration: 0.5 }}
              style={{ overflow: 'hidden' }}
              initial={{ height: 0 }}
              animate={{ height: 'initial' }}
              exit={{ height: 0 }}
            >
              <motion.div
                transition={{ duration: 0.5 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="link"
                onClick={() => {
                  history.push('/flowers');
                }}
              >
                Flowers
              </motion.div>
              <motion.div
                transition={{ duration: 0.5 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="link"
                onClick={() => {
                  history.push('/landscapes');
                }}
              >
                Landscapes
              </motion.div>
              <motion.div
                transition={{ duration: 0.5 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="link"
                onClick={() => {
                  history.push('/others');
                }}
              >
                Others
              </motion.div>
            </motion.div>

            )}
          </AnimatePresence>

        </div>
        <div className="link">
          <Link to="/mix-media-works">
            Mix Media Works
          </Link>
        </div>
        <div className="link">
          <Link to="/contact">
            Contact
          </Link>
        </div>
        <div style={{ marginBottom: 16 }} className="link">
          <Link to="/about">
            About
          </Link>
        </div>
        <div style={{ height: '100%' }} />
        <div className="social-media">
          <Facebook onClick={() => window.open('https://www.facebook.com/MisshersCreativity')} className="hand logo" width="40" height="40" />
          <Instagram onClick={() => window.open('https://www.instagram.com/missher_art/')} className="hand logo" width="40" height="40" />
        </div>
        <div className="copyrights">
          <div className="center text">
            Copyright &nbsp;&copy; 2022 Missher. All rights reserved.
          </div>
          <div className="center text">
            This website has been created by Bo with love.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
