import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import Horizontal from '../../img/wetransfer_img_20211222_111809-jpg_2021-12-23_1918/horizontal3.jpg';
import Horizontal2 from '../../img/wetransfer_img_20211222_111809-jpg_2021-12-23_1918/horizontal2.jpg';
import Background from '../../img/background.jpeg';
import Background1 from '../../img/background1.jpeg';
import Vertical from '../../img/wetransfer_img_20211222_111809-jpg_2021-12-23_1918/vertical1.jpg';
import Vertical2 from '../../img/wetransfer_img_20211222_111809-jpg_2021-12-23_1918/vertical2.jpg';

const images = [Background, Horizontal, Background1, Horizontal2];
const verticalImages = [Background, Vertical, Background1, Vertical2];

const variants = {
  hidden: { opacity: 0 },
  visible: {

    opacity: 0.3,
    // display: 'initial',
  },
  exiting: {
    opacity: 0,
  },
};

const BackgroundImages = () => {
  const [selectedImage, setSelectedImage] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (selectedImage === 2) {
        setSelectedImage(0);
        return;
      }
      setSelectedImage((prevValue) => {
        if (prevValue === 3) { return 0; }
        return prevValue + 1;
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (

    <>
      <motion.div style={{
        position: 'fixed',
        backgroundColor: 'black',
        left: 0,
        top: 80,
        right: 0,
        bottom: 0,
        overflow: 'hidden',
      }}
      >
        <AnimatePresence>
          {window.screen.availWidth < 800 ? (
            <motion.img
              className="vertical-image"
              variants={variants}
              key={images[selectedImage]}
              transition={{ duration: 0.7 }}
              initial="hidden"
              animate="visible"
              exit="exiting"
              style={{
                position: 'fixed', height: '100%', opacity: '30%', scale: 1.5,
              }}
              src={verticalImages[selectedImage]}
              alt="background-art-photograph"
            />
          )
            : (
              <motion.img
                className="horizontal-image"
                variants={variants}
                key={images[selectedImage]}
                transition={{ duration: 0.7 }}
                initial="hidden"
                animate="visible"
                exit="exiting"
                style={{
                  position: 'fixed', height: '100%', opacity: '30%', scale: 1.5,
                }}
                src={images[selectedImage]}
                alt="background-art-photograph"
              />
            )}

        </AnimatePresence>

      </motion.div>
    </>
  );
};

export default BackgroundImages;
