/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import ReactPlayer from 'react-player/lazy';

const LoveJacqueline = ({ loveVisible, setLoveVisible }: any) => (

  <AnimatePresence>
    {loveVisible && (
    <motion.div
      className="LoveJacqueline center"
      onClick={() => setLoveVisible(false)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        transition={{ duration: 0.7 }}
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0 }}
        className="content"
      >
        <motion.div>

          <ReactPlayer style={{ marginBottom: 16 }} controls url="https://vimeo.com/667107583" />
          <motion.div className="center text">
            Dear Жаки,
          </motion.div>
          <motion.div className="center text">
            Thank you for inspiring this website.
          </motion.div>
          <motion.div className="center text">
            Thank you for inspiring me to be a better man.
          </motion.div>
          <motion.div className="center text">
            Thank you for everything.
          </motion.div>
          <motion.div className="text" />
          <motion.div className="text" />
          <motion.div className="text" />
          <motion.div className="text" />
          <motion.div className="center text">
            With Love,
          </motion.div>

          <motion.div className="center text">
            Bo
          </motion.div>

        </motion.div>
      </motion.div>
    </motion.div>
    )}

  </AnimatePresence>

);

export default LoveJacqueline;
