/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactComponent as LogoSVG } from '../../img/logo.svg';
import { ReactComponent as MenuSVG } from '../../img/menu.svg';
import { ReactComponent as DownArrow } from '../../img/down-arrow.svg';
import NavMenuModal from './NavMenuModal';

const NavBar = observer(() => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [hovering, setHovering] = useState(false);

  return (
    <div className="NavBar flex">
      <MenuSVG style={{ opacity: 0 }} onClick={() => setVisible(!visible)} className="menu hand" />
      <NavMenuModal visible={visible} setVisible={setVisible} />
      <div className="menu-separator" />
      <LogoSVG onClick={() => history.push('/')} className="animated-logo hand" color="#133337" fill="#133337" />
      <div className="menu-separator" />
      <MenuSVG onClick={() => setVisible(!visible)} className="menu hand" />
      <div className="links-container">
        <div onMouseUp={() => history.push('/')} style={{ paddingLeft: '10%' }} className="center hand" />
        <div
          onMouseLeave={() => setHovering(false)}
          onMouseEnter={() => setHovering(true)}
          style={{
            position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center',
          }}
          className="nav-btn"
        >
          <AnimatePresence>
            {hovering && (
              <motion.div
                transition={{ duration: 0.5 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{
                  borderRadius: '8px',
                  backgroundColor: '#d1e9e9',
                  // marginLeft: 32,
                  paddingLeft: 32,
                  top: 58,
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'absolute',
                  width: '100%',
                  gap: 16,
                }}
              >
                <motion.div
                  transition={{ duration: 0.5 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onClick={() => history.push('/flowers')}
                  // style={{ position: 'absolute', top: 80 }}
                >
                  Flowers
                </motion.div>
                <motion.div
                  transition={{ duration: 1 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onClick={() => history.push('/landscapes')}
                  // style={{ position: 'absolute', top:  }}
                >
                  Landscapes
                </motion.div>
                <motion.div
                  style={{ paddingBottom: 16 }}
                  transition={{ duration: 1 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onClick={() => history.push('/others')}
                  // style={{ position: 'absolute', top:  }}
                >
                  Others
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
          <div>
            Watercolours
          </div>
          <DownArrow style={{ marginLeft: 8, paddingTop: 6 }} width="20" height="20" />
        </div>
        <div className="nav-btn" style={{ width: 239 }}>
          <Link to="/mix-media-works">
            Mix Media Works
          </Link>
        </div>
        <div className="nav-btn">
          <Link to="/about">
            About
          </Link>
        </div>
        <div className="nav-btn">
          <Link to="/contact">
            Contact
          </Link>
        </div>
      </div>
    </div>
  );
});

export default NavBar;
