/* eslint-disable no-empty-pattern */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import {
  Formik, Form, useField,
} from 'formik';
import * as Yup from 'yup';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import emailjs from 'emailjs-com';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';
// import Horizontal from '../../img/wetransfer_img_20211222_111809-jpg_2021-12-23_1918/horizontal3.jpg';
import { ReactComponent as Close } from '../../img/close.svg';
import BackgroundImages from './BackgroundImages';
// import MessageSent from '../LoveJacqueline/MessageSent';
// import Vertical from '../../img/wetransfer_img_20211222_111809-jpg_2021-12-23_1918/vertical1.jpg';

const MyTextInput = ({ label, ...props }: any) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      {/* <label htmlFor={props.id || props.name}>{label}</label> */}
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

const SelectedImage = observer(({ image, globalStore }) => {
  const cover = image.cover.data.attributes;

  return (
    <motion.div
      className="center-flex responsive-image smaller"
      transition={{ duration: 1, restSpeed: 1 }}
      style={{ position: 'relative' }}
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
    >
      <motion.div
        className="center-flex hand"
        onMouseUp={() => globalStore.setSelectedImage(undefined)}
        whileHover={{ backgroundColor: 'rgba(0, 0, 0, 0.652)', scale: 1.1 }}
        whileTap={{ backgroundColor: 'rgba(0, 0, 0, 0.652)', scale: 1.2 }}
        style={{
          position: 'absolute', top: 0, right: 0, padding: 8, margin: 8, borderRadius: '20px', backgroundColor: 'rgba(0, 0, 0, 0.522)',
        }}
      >
        <Close fill="white" />
      </motion.div>
      <img src={cover.url} alt={image.Title} width="100%" />
    </motion.div>
  );
});

// And now we can use these
const Contact = observer(({ globalStore }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      {/* <MessageSent loveVisible={visible} setLoveVisible={setVisible} /> */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:image" content="https://ora-attachments.s3.amazonaws.com/12661954fd98519fb37705d8e2f2682d52568276f7261.jpeg" />

        <meta property="og:title" content="Missher Art | Contact" />
        <title>Missher Art | Contact</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <BackgroundImages />
      <div className="Contact" style={!isEmpty(globalStore.selectedImage) ? { justifyContent: 'initial' } : undefined}>
        <AnimateSharedLayout>

          <AnimatePresence>
            {!isEmpty(globalStore.selectedImage) && <SelectedImage image={globalStore.selectedImage} globalStore={globalStore} />}

          </AnimatePresence>
          <AnimatePresence>
            {!visible && isEmpty(globalStore.selectedImage)
            && (
            <motion.div
              style={{ color: 'white' }}
              transition={{ duration: 1 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              Contact with the artist
            </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>

            {!visible && !isEmpty(globalStore.selectedImage) && (
              <motion.div
                transition={{ duration: 1 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{ flexDirection: 'column' }}
                className="center-flex"
              >
                <div style={{
                  margin: 8, color: 'white', fontSize: 24, fontWeight: 700,
                }}
                >
                  {globalStore.selectedImage.Title}

                </div>
                <div
                  className="price"
                  style={{
                    margin: 4, color: 'white', fontSize: 20, fontWeight: 400,
                  }}
                >
                  {globalStore.selectedImage.Price}
                  {' '}
                  BGN
                </div>
                <div
                  className="size"
                  style={{
                    margin: 4, color: 'white', fontSize: 20, fontWeight: 400,
                  }}
                >
                  {globalStore.selectedImage.Size}
                  {' '}
                  cm
                </div>
                {globalStore.selectedImage.Technique && (
                <div
                  className="price"
                  style={{
                    margin: 4, color: 'white', fontSize: 20, fontWeight: 400,
                  }}
                >
                  {globalStore.selectedImage.Technique}
                </div>
                )}
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {!visible && (

            <motion.div
              className="center-flex"
              style={{ width: '100%' }}
              transition={{ duration: 1 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >

              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  inquiry: '',
                }}
                validationSchema={Yup.object({
                  firstName: Yup.string()
                    .max(15, 'Must be 15 characters or less')
                    .required('Required'),
                  lastName: Yup.string()
                    .max(20, 'Must be 20 characters or less')
                    .required('Required'),
                  email: Yup.string()
                    .email('Invalid email addresss`')
                    .required('Required'),
                  inquiry: Yup.string()
                    .max(500, 'Must be 500 characters or less')
                    .required('Please ask us a question'),

                })}
                onSubmit={({
                  firstName, lastName, email, inquiry,
                }) => {
                  emailjs.send('service_q5wt775', 'template_vx3x4u5', {
                    title: globalStore.selectedImage.Title,
                    price: globalStore.selectedImage ? globalStore.selectedImage.Price : '',
                    from_name: `${firstName} ${lastName}`,
                    from_email: email,
                    message: inquiry,
                  }, 'user_KEK3SuLoqnKOpbFvlzOOR')
                    .then((response) => {
                      console.log('SUCCESS!', response.status, response.text);
                    }, (err) => {
                      console.log('FAILED...', err);
                    });
                  setVisible(true);
                  globalStore.setSelectedImage(undefined);
                }}
              >
                <Form>

                  <>
                    {' '}
                    <MyTextInput
                      label="First Name"
                      name="firstName"
                      type="text"
                      placeholder="First name"
                    />
                    <MyTextInput
                      label="Last Name"
                      name="lastName"
                      type="text"
                      placeholder="Last name"
                    />
                    <MyTextInput
                      label="Email Address"
                      name="email"
                      type="email"
                      placeholder="Email"
                    />
                    <MyTextInput
                      label="Inquiry"
                      name="inquiry"
                      type="text"
                      placeholder="Ask a question..."
                    />

                    <motion.button whileHover={{ backgroundColor: 'rgba(0, 132, 255, 0.548)', color: 'white' }} whileTap={{ backgroundColor: 'rgba(0, 132, 255, 0.548)', color: 'white' }} type="submit">Submit</motion.button>
                  </>

                </Form>
              </Formik>
            </motion.div>
            )}
          </AnimatePresence>
        </AnimateSharedLayout>
      </div>
      <AnimatePresence>
        {visible && (
        <motion.div
          className="message-sent center-flex"
          transition={{ duration: 2.5 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div className="text center">
            Your message has been sent.
          </motion.div>
          <motion.div className="text center">
            Expect an answer as soon as possible.
          </motion.div>
          <motion.div className="text center">
            Thank you!
          </motion.div>
        </motion.div>
        )}
      </AnimatePresence>

    </>
  );
});

export default Contact;
