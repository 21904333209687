/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const NavMenuModal = ({ visible, setVisible }: any) => {
  const history = useHistory();
  const [shown, setShown] = useState(false);

  return (
    <div className="nav-menu-modal">

      <AnimatePresence>
        {visible && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
              // onMouseUp={() => setVisible(false)}
            onClick={() => setVisible(false)}
            className="backdrop"
          />
          <motion.div className="links" onClick={() => setVisible(false)}>
            <div
              // style={{ position: 'relative' }}
              className="link"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setShown(!shown);
              }}
            >
              Watercolours
              <AnimatePresence>
                {shown && (
                  <motion.div
                    transition={{ duration: 0.5 }}
                    style={{ overflow: 'hidden' }}
                    initial={{ height: 0 }}
                    animate={{ height: 'initial' }}
                    exit={{ height: 0 }}
                  >
                    <motion.div
                      transition={{ duration: 0.5 }}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="link"
                      onClick={() => {
                        setVisible(false);
                        history.push('/flowers');
                      }}
                    >
                      Flowers
                    </motion.div>
                    <motion.div
                      transition={{ duration: 0.5 }}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="link"
                      onClick={() => {
                        setVisible(false);
                        history.push('/landscapes');
                      }}
                    >
                      Landscapes
                    </motion.div>
                    <motion.div
                      transition={{ duration: 0.5 }}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="link"
                      onClick={() => {
                        setVisible(false);
                        history.push('/others');
                      }}
                    >
                      Others
                    </motion.div>
                  </motion.div>

                )}
              </AnimatePresence>

            </div>
            <div className="link" onClick={() => history.push('/mix-media-works')}>
              Mix Media Works
            </div>
            <div className="link" onClick={() => history.push('/contact')}>
              Contact
            </div>
            <div className="link" onClick={() => history.push('/about')}>
              About
            </div>
          </motion.div>
        </>
        )}
      </AnimatePresence>

    </div>

  );
};

export default NavMenuModal;
